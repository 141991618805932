import axiosApi from "../axios";
export default {
  async getorders(params) {
    return await axiosApi
      .get(`/orders`, { params: params })
      .then((res) => {
        console.log(res.data + "this is data ");
        return res.data;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );

        return e.response?.data?.errors;
      });
  },
  async updateorder(order) {
    return await axiosApi
      .put(`/orders/${order._id}/pay`)
      .then((res) => {
        console.log(res.data + "this is data ");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  // async createorder(order) {
  //   return await axiosApi
  //     .post(`/orderes`, order)
  //     .then((res) => {
  //       console.log(res.data + "this is data ");
  //       return res;
  //     })
  //     .catch((e) => {
  //       console.log(
  //         JSON.stringify(e.response?.data?.errors) + "this is an error "
  //       );
  //       return e.response?.data?.errors;
  //     });
  // },
};

<template>
  <div>
    <!-- modal trigger button -->
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      @click="updateorder"
    >
      <feather-icon icon="Edit2Icon" class="mr-50" />
      <span>change payment</span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ordersApi from "@/apiServices/ordersApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import imagesApi from "@/apiServices/imagesApi";

export default {
  props: ["order"],

  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      options: ["video", "pdf", "powerpoint"],

      prams: {
        id: "",
      },
    };
  },
  created() {
    // console.log(this.order);
    // this.order.image = null;
  },
  methods: {
    updateorder() {
      ordersApi.updateorder(this.order).then((response) => {
        if (response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "order updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    showModal() {
      console.log(this.id + "this id");

      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden

      this.$refs["my-modal"].toggle("#toggle-btn");
    },
  },
};
</script>

<template>
  <b-card-code title="All orders " no-body>
    <b-card-body>
      <b-table
        v-if="orders.length > 0"
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :items="orders"
        :fields="fields"
      >
        <template #cell(createdAt)="data">
          {{ data.item.createdAt.slice(0, 10) }}
        </template>
        <template #cell(user)="data">
          {{ data.item.user.name }}
        </template>
        <template #cell(cartItems)="data">
          <b-row v-if="data.item.cartItems">
            <b-badge
              pill
              v-for="(item, index) in data.item.cartItems"
              :id="item.id"
              :key="item.id"
              variant="info"
            >
              {{ item.title }}
            </b-badge>
          </b-row>
        </template>
        <template #cell(isPaid)="data">
          <!-- {{ data.item.isPaid }} -->
          <b-badge v-if="data.item.isPaid" variant="success">
            <feather-icon icon="CheckIcon" class="mr-25" />
            <span>Paid</span>
          </b-badge>
          <b-badge v-else variant="danger">
            <feather-icon icon="XIcon" class="mr-25" />
            <span>not Paid</span>
          </b-badge>
        </template>
        <template #cell(Action)="data">
          <editorder :order="data.item" />
        </template>
      </b-table>
    </b-card-body>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="prams.limit"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card-code>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
// import { codeKitchenSink } from "./code";
import ordersApi from "@/apiServices/ordersApi";
import editorder from "@/views/orders pages/edit.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    editorder,
  },
  data() {
    return {
      prams: {
        sort: "-createdAt",
        page: 1,
        limit: 5,
      },
      options: ["video", "pdf", "powerpoint"],

      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "createdAt",
          label: "order date",
        },
        { key: "user", label: "Student" },
        { key: "cartItems", label: "courses" },
        { key: "isPaid", label: "paymet Status" },
        { key: "totalOrderPrice", label: "Total order price" },

        "Action",
      ],
      /* eslint-disable global-require */
      orders: [],
      /* eslint-disable global-require */
      status: [],
      //   codeKitchenSink,
    };
  },
  created() {
    this.getorders();
  },
  computed: {},
  mounted() {
    // this.totalRows = this.items.length;
  },
  watch: {
    currentPage() {
      this.prams.page = this.currentPage;

      this.getorders();
    },
    filter() {
      this.prams.page = this.currentPage;

      this.getorders();
    },
  },
  methods: {
    deleteorderromlist(id) {
      console.log("hi from parent " + id + "******" + this.orders.length);
      this.orders = this.orders.filter(function (obj) {
        return obj._id != id;
      });
      console.log("hi ******" + this.orders.length);
    },
    getorders() {
      ordersApi.getorders(this.prams).then((response) => {
        this.orders = [];
        if (response.data) {
          this.orders = response.data;
          this.currentPage = response.paginationResult?.currentPage;
          this.totalRows =
            response.paginationResult?.numberOfPages * this.prams.limit;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "get All orders",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      //   this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
